<template>
  <v-row>
    <v-col>
      <v-select
        :items="dxcc_entities"
        @input="selectEntity"
        v-model="entity"
        label="DX Entity"
        item-text="entityName"
        :prepend-icon="$vuetify.icons.values.mdiEarth"
        return-object
        single-line
        xs12
        md6
      />
    </v-col>
    <v-col>
      <v-select
        :items="locations"
        @input="selected"
        v-model="location"
        label="Location (State / Province / Region / Area)"
        item-text="locationDisplay"
        :prepend-icon="$vuetify.icons.values.mdiEarth"
        :disabled="!entity.entityName"
        return-object
        single-line
        xs12
        md6
      />
    </v-col>
    <v-col v-if="showSearch">
      <v-text-field
        v-model="search"
        @keyup="doSearch"
        :prepend-icon="$vuetify.icons.values.mdiMagnify"
        label="Filter"
        :disabled="!location.entityId"
        single-line
        xs12
        md6
      ></v-text-field>
    </v-col>
  </v-row>
</template>

<script>
import axios from "axios";

export default {
  props: {
    preselectLocation: { default: null },
    showSearch: { type: Boolean, default: false },
  },
  data() {
    return {
      search: "",
      locationParksHeaders: [
        {
          text: "Reference",
          value: "reference",
        },
        {
          text: "Name",
          value: "name",
        },
      ],
      allLocations: [],
      location: [],
      allEntityNames: [],
      entity: {},
      locations: [],
      locationParks: [],
      needLoad: true,
    };
  },
  mounted() {
    this.getLocations();
  },
  computed: {
    dxcc_entities: function () {
      // Sort the list of DXCC entities
      var list_dxccs = this.allLocations.slice().sort(function (a, b) {
        return a.entityName > b.entityName ? 1 : -1;
      });
      // Add US at the top
      list_dxccs.splice(
        0,
        0,
        {
          entityDisplay: "United States of America (US)",
          entityName: "United States of America",
          entityId: 291,
        },
        { entityName: "------------------------" }
      );
      return list_dxccs;
    },
    pota_locations: function () {
      return this.locations.slice().sort(function (a, b) {
        return a.entityName > b.entityName ? 1 : -1;
      });
    },
  },
  methods: {
    handlePreselect() {
      if (this.preselectLocation) {
        var location = this.allLocations.filter(
          (x) => x.locationDesc == this.preselectLocation
        );
        if (location && location.length > 0) {
          this.entity = location[0];
          this.selectEntity(this.entity);
          this.location = location[0];
          this.selected();
        }
      }
    },
    getLocations() {
      if (!this.needLoad) {
        this.handlePreselect();
        return;
      }

      this.needLoad = false;

      axios
        .get(`https://${process.env.VUE_APP_API_URL}/locations`)
        .then((response) => {
          this.allLocations = response.data;

          for (var i = 0; i < this.allLocations.length; i++) {
            this.allLocations[i].entityDesc =
              this.allLocations[i].locationDesc.split("-")[0];
            this.allLocations[
              i
            ].entityDisplay = `${this.allLocations[i].entityName} (${this.allLocations[i].entityDesc})`;
            this.allLocations[
              i
            ].locationDisplay = `${this.allLocations[i].locationName} (${this.allLocations[i].locationDesc})`;
            this.allLocations[i].all = false;
          }

          this.handlePreselect();
        });
    },
    selectEntity(value) {
      // This function dynamically loads the filtered list of locations in the second select
      var locations = this.allLocations
        .filter(function (item) {
          return item.entityName === value.entityName;
        })
        .slice()
        .sort(function (a, b) {
          return a.locationName > b.locationName ? 1 : -1;
        });

      if (locations.length > 1) {
        locations.push({ locationDisplay: "------------------------" });
        locations.push({
          locationDisplay: "All Locations (slow!)",
          entityId: value.entityId,
          all: true,
          locationDesc: `entity-${value.entityId}`,
        });
      }

      this.locations = locations;

      if (this.locations.length == 1) {
        this.location = this.locations[0];
        this.selected();
      }
    },
    selected() {
      this.$emit("selected", this.location);
    },
    doSearch() {
      this.$emit("search", this.search);
    },
  },
};
</script>
